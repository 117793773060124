// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-legion-index-mdx": () => import("./../../../src/pages/about-legion/index.mdx" /* webpackChunkName: "component---src-pages-about-legion-index-mdx" */),
  "component---src-pages-annual-report-2023-index-jsx": () => import("./../../../src/pages/annual-report-2023/index.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-index-jsx" */),
  "component---src-pages-annual-report-2023-section-footer-jsx": () => import("./../../../src/pages/annual-report-2023/section-footer.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-footer-jsx" */),
  "component---src-pages-annual-report-2023-section-gamification-jsx": () => import("./../../../src/pages/annual-report-2023/section-gamification.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-gamification-jsx" */),
  "component---src-pages-annual-report-2023-section-hero-jsx": () => import("./../../../src/pages/annual-report-2023/section-hero.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-hero-jsx" */),
  "component---src-pages-annual-report-2023-section-implement-jsx": () => import("./../../../src/pages/annual-report-2023/section-implement.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-implement-jsx" */),
  "component---src-pages-annual-report-2023-section-influence-jsx": () => import("./../../../src/pages/annual-report-2023/section-influence.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-influence-jsx" */),
  "component---src-pages-annual-report-2023-section-leveling-jsx": () => import("./../../../src/pages/annual-report-2023/section-leveling.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-leveling-jsx" */),
  "component---src-pages-annual-report-2023-section-marque-jsx": () => import("./../../../src/pages/annual-report-2023/section-marque.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-marque-jsx" */),
  "component---src-pages-annual-report-2023-section-principle-jsx": () => import("./../../../src/pages/annual-report-2023/section-principle.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-principle-jsx" */),
  "component---src-pages-annual-report-2023-section-report-jsx": () => import("./../../../src/pages/annual-report-2023/section-report.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-report-jsx" */),
  "component---src-pages-annual-report-2023-section-support-jsx": () => import("./../../../src/pages/annual-report-2023/section-support.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-support-jsx" */),
  "component---src-pages-annual-report-2023-section-team-jsx": () => import("./../../../src/pages/annual-report-2023/section-team.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-team-jsx" */),
  "component---src-pages-annual-report-2023-section-testimony-jsx": () => import("./../../../src/pages/annual-report-2023/section-testimony.jsx" /* webpackChunkName: "component---src-pages-annual-report-2023-section-testimony-jsx" */),
  "component---src-pages-brand-gallery-agree-components-mdx": () => import("./../../../src/pages/brand-gallery/agree/components.mdx" /* webpackChunkName: "component---src-pages-brand-gallery-agree-components-mdx" */),
  "component---src-pages-brand-gallery-agree-foundation-mdx": () => import("./../../../src/pages/brand-gallery/agree/foundation.mdx" /* webpackChunkName: "component---src-pages-brand-gallery-agree-foundation-mdx" */),
  "component---src-pages-brand-gallery-agree-tone-of-voice-mdx": () => import("./../../../src/pages/brand-gallery/agree/tone-of-voice.mdx" /* webpackChunkName: "component---src-pages-brand-gallery-agree-tone-of-voice-mdx" */),
  "component---src-pages-brand-gallery-logee-components-mdx": () => import("./../../../src/pages/brand-gallery/logee/components.mdx" /* webpackChunkName: "component---src-pages-brand-gallery-logee-components-mdx" */),
  "component---src-pages-brand-gallery-logee-foundation-mdx": () => import("./../../../src/pages/brand-gallery/logee/foundation.mdx" /* webpackChunkName: "component---src-pages-brand-gallery-logee-foundation-mdx" */),
  "component---src-pages-brand-gallery-logee-tone-of-voice-mdx": () => import("./../../../src/pages/brand-gallery/logee/tone-of-voice.mdx" /* webpackChunkName: "component---src-pages-brand-gallery-logee-tone-of-voice-mdx" */),
  "component---src-pages-components-accordion-android-compose-mdx": () => import("./../../../src/pages/components/accordion/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-accordion-android-compose-mdx" */),
  "component---src-pages-components-accordion-android-xml-mdx": () => import("./../../../src/pages/components/accordion/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-accordion-android-xml-mdx" */),
  "component---src-pages-components-accordion-guideline-mdx": () => import("./../../../src/pages/components/accordion/guideline.mdx" /* webpackChunkName: "component---src-pages-components-accordion-guideline-mdx" */),
  "component---src-pages-components-accordion-ios-mdx": () => import("./../../../src/pages/components/accordion/ios.mdx" /* webpackChunkName: "component---src-pages-components-accordion-ios-mdx" */),
  "component---src-pages-components-accordion-website-mdx": () => import("./../../../src/pages/components/accordion/website.mdx" /* webpackChunkName: "component---src-pages-components-accordion-website-mdx" */),
  "component---src-pages-components-alert-android-compose-mdx": () => import("./../../../src/pages/components/alert/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-alert-android-compose-mdx" */),
  "component---src-pages-components-alert-android-xml-mdx": () => import("./../../../src/pages/components/alert/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-alert-android-xml-mdx" */),
  "component---src-pages-components-alert-guideline-mdx": () => import("./../../../src/pages/components/alert/guideline.mdx" /* webpackChunkName: "component---src-pages-components-alert-guideline-mdx" */),
  "component---src-pages-components-alert-ios-mdx": () => import("./../../../src/pages/components/alert/ios.mdx" /* webpackChunkName: "component---src-pages-components-alert-ios-mdx" */),
  "component---src-pages-components-alert-react-native-mdx": () => import("./../../../src/pages/components/alert/react-native.mdx" /* webpackChunkName: "component---src-pages-components-alert-react-native-mdx" */),
  "component---src-pages-components-alert-website-mdx": () => import("./../../../src/pages/components/alert/website.mdx" /* webpackChunkName: "component---src-pages-components-alert-website-mdx" */),
  "component---src-pages-components-alertdialog-react-native-mdx": () => import("./../../../src/pages/components/alertdialog/react-native.mdx" /* webpackChunkName: "component---src-pages-components-alertdialog-react-native-mdx" */),
  "component---src-pages-components-anchor-android-compose-mdx": () => import("./../../../src/pages/components/anchor/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-anchor-android-compose-mdx" */),
  "component---src-pages-components-anchor-android-xml-mdx": () => import("./../../../src/pages/components/anchor/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-anchor-android-xml-mdx" */),
  "component---src-pages-components-anchor-guideline-mdx": () => import("./../../../src/pages/components/anchor/guideline.mdx" /* webpackChunkName: "component---src-pages-components-anchor-guideline-mdx" */),
  "component---src-pages-components-anchor-ios-mdx": () => import("./../../../src/pages/components/anchor/ios.mdx" /* webpackChunkName: "component---src-pages-components-anchor-ios-mdx" */),
  "component---src-pages-components-anchor-react-native-mdx": () => import("./../../../src/pages/components/anchor/react-native.mdx" /* webpackChunkName: "component---src-pages-components-anchor-react-native-mdx" */),
  "component---src-pages-components-anchor-website-mdx": () => import("./../../../src/pages/components/anchor/website.mdx" /* webpackChunkName: "component---src-pages-components-anchor-website-mdx" */),
  "component---src-pages-components-avatar-android-compose-mdx": () => import("./../../../src/pages/components/avatar/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-avatar-android-compose-mdx" */),
  "component---src-pages-components-avatar-android-xml-mdx": () => import("./../../../src/pages/components/avatar/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-avatar-android-xml-mdx" */),
  "component---src-pages-components-avatar-guideline-mdx": () => import("./../../../src/pages/components/avatar/guideline.mdx" /* webpackChunkName: "component---src-pages-components-avatar-guideline-mdx" */),
  "component---src-pages-components-avatar-ios-mdx": () => import("./../../../src/pages/components/avatar/ios.mdx" /* webpackChunkName: "component---src-pages-components-avatar-ios-mdx" */),
  "component---src-pages-components-avatar-ios-uikit-mdx": () => import("./../../../src/pages/components/avatar/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-components-avatar-ios-uikit-mdx" */),
  "component---src-pages-components-avatar-react-native-mdx": () => import("./../../../src/pages/components/avatar/react-native.mdx" /* webpackChunkName: "component---src-pages-components-avatar-react-native-mdx" */),
  "component---src-pages-components-avatar-website-mdx": () => import("./../../../src/pages/components/avatar/website.mdx" /* webpackChunkName: "component---src-pages-components-avatar-website-mdx" */),
  "component---src-pages-components-badge-android-compose-mdx": () => import("./../../../src/pages/components/badge/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-badge-android-compose-mdx" */),
  "component---src-pages-components-badge-android-xml-mdx": () => import("./../../../src/pages/components/badge/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-badge-android-xml-mdx" */),
  "component---src-pages-components-badge-guideline-mdx": () => import("./../../../src/pages/components/badge/guideline.mdx" /* webpackChunkName: "component---src-pages-components-badge-guideline-mdx" */),
  "component---src-pages-components-badge-ios-mdx": () => import("./../../../src/pages/components/badge/ios.mdx" /* webpackChunkName: "component---src-pages-components-badge-ios-mdx" */),
  "component---src-pages-components-badge-ios-uikit-mdx": () => import("./../../../src/pages/components/badge/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-components-badge-ios-uikit-mdx" */),
  "component---src-pages-components-badge-website-mdx": () => import("./../../../src/pages/components/badge/website.mdx" /* webpackChunkName: "component---src-pages-components-badge-website-mdx" */),
  "component---src-pages-components-bottombar-android-mdx": () => import("./../../../src/pages/components/bottombar/android.mdx" /* webpackChunkName: "component---src-pages-components-bottombar-android-mdx" */),
  "component---src-pages-components-bottomsheet-android-compose-mdx": () => import("./../../../src/pages/components/bottomsheet/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-bottomsheet-android-compose-mdx" */),
  "component---src-pages-components-bottomsheet-android-xml-mdx": () => import("./../../../src/pages/components/bottomsheet/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-bottomsheet-android-xml-mdx" */),
  "component---src-pages-components-bottomsheet-guideline-mdx": () => import("./../../../src/pages/components/bottomsheet/guideline.mdx" /* webpackChunkName: "component---src-pages-components-bottomsheet-guideline-mdx" */),
  "component---src-pages-components-bottomsheet-ios-mdx": () => import("./../../../src/pages/components/bottomsheet/ios.mdx" /* webpackChunkName: "component---src-pages-components-bottomsheet-ios-mdx" */),
  "component---src-pages-components-bottomsheet-react-native-mdx": () => import("./../../../src/pages/components/bottomsheet/react-native.mdx" /* webpackChunkName: "component---src-pages-components-bottomsheet-react-native-mdx" */),
  "component---src-pages-components-box-website-mdx": () => import("./../../../src/pages/components/box/website.mdx" /* webpackChunkName: "component---src-pages-components-box-website-mdx" */),
  "component---src-pages-components-breadcrumb-guideline-mdx": () => import("./../../../src/pages/components/breadcrumb/guideline.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-guideline-mdx" */),
  "component---src-pages-components-breadcrumb-website-mdx": () => import("./../../../src/pages/components/breadcrumb/website.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-website-mdx" */),
  "component---src-pages-components-button-android-mdx": () => import("./../../../src/pages/components/button/android.mdx" /* webpackChunkName: "component---src-pages-components-button-android-mdx" */),
  "component---src-pages-components-button-guideline-mdx": () => import("./../../../src/pages/components/button/guideline.mdx" /* webpackChunkName: "component---src-pages-components-button-guideline-mdx" */),
  "component---src-pages-components-button-ios-mdx": () => import("./../../../src/pages/components/button/ios.mdx" /* webpackChunkName: "component---src-pages-components-button-ios-mdx" */),
  "component---src-pages-components-button-ios-uikit-mdx": () => import("./../../../src/pages/components/button/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-components-button-ios-uikit-mdx" */),
  "component---src-pages-components-button-react-native-mdx": () => import("./../../../src/pages/components/button/react-native.mdx" /* webpackChunkName: "component---src-pages-components-button-react-native-mdx" */),
  "component---src-pages-components-button-website-mdx": () => import("./../../../src/pages/components/button/website.mdx" /* webpackChunkName: "component---src-pages-components-button-website-mdx" */),
  "component---src-pages-components-card-guideline-mdx": () => import("./../../../src/pages/components/card/guideline.mdx" /* webpackChunkName: "component---src-pages-components-card-guideline-mdx" */),
  "component---src-pages-components-card-website-mdx": () => import("./../../../src/pages/components/card/website.mdx" /* webpackChunkName: "component---src-pages-components-card-website-mdx" */),
  "component---src-pages-components-carousel-guideline-mdx": () => import("./../../../src/pages/components/carousel/guideline.mdx" /* webpackChunkName: "component---src-pages-components-carousel-guideline-mdx" */),
  "component---src-pages-components-carousel-website-mdx": () => import("./../../../src/pages/components/carousel/website.mdx" /* webpackChunkName: "component---src-pages-components-carousel-website-mdx" */),
  "component---src-pages-components-checkbox-android-compose-mdx": () => import("./../../../src/pages/components/checkbox/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-android-compose-mdx" */),
  "component---src-pages-components-checkbox-android-xml-mdx": () => import("./../../../src/pages/components/checkbox/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-android-xml-mdx" */),
  "component---src-pages-components-checkbox-guideline-mdx": () => import("./../../../src/pages/components/checkbox/guideline.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-guideline-mdx" */),
  "component---src-pages-components-checkbox-ios-mdx": () => import("./../../../src/pages/components/checkbox/ios.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-ios-mdx" */),
  "component---src-pages-components-checkbox-ios-uikit-mdx": () => import("./../../../src/pages/components/checkbox/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-ios-uikit-mdx" */),
  "component---src-pages-components-checkbox-react-native-mdx": () => import("./../../../src/pages/components/checkbox/react-native.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-react-native-mdx" */),
  "component---src-pages-components-checkbox-website-mdx": () => import("./../../../src/pages/components/checkbox/website.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-website-mdx" */),
  "component---src-pages-components-chips-android-compose-mdx": () => import("./../../../src/pages/components/chips/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-chips-android-compose-mdx" */),
  "component---src-pages-components-chips-android-xml-mdx": () => import("./../../../src/pages/components/chips/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-chips-android-xml-mdx" */),
  "component---src-pages-components-chips-guideline-mdx": () => import("./../../../src/pages/components/chips/guideline.mdx" /* webpackChunkName: "component---src-pages-components-chips-guideline-mdx" */),
  "component---src-pages-components-chips-ios-mdx": () => import("./../../../src/pages/components/chips/ios.mdx" /* webpackChunkName: "component---src-pages-components-chips-ios-mdx" */),
  "component---src-pages-components-chips-react-native-mdx": () => import("./../../../src/pages/components/chips/react-native.mdx" /* webpackChunkName: "component---src-pages-components-chips-react-native-mdx" */),
  "component---src-pages-components-chips-website-mdx": () => import("./../../../src/pages/components/chips/website.mdx" /* webpackChunkName: "component---src-pages-components-chips-website-mdx" */),
  "component---src-pages-components-container-website-mdx": () => import("./../../../src/pages/components/container/website.mdx" /* webpackChunkName: "component---src-pages-components-container-website-mdx" */),
  "component---src-pages-components-datepicker-guideline-mdx": () => import("./../../../src/pages/components/datepicker/guideline.mdx" /* webpackChunkName: "component---src-pages-components-datepicker-guideline-mdx" */),
  "component---src-pages-components-datepicker-website-mdx": () => import("./../../../src/pages/components/datepicker/website.mdx" /* webpackChunkName: "component---src-pages-components-datepicker-website-mdx" */),
  "component---src-pages-components-divider-android-mdx": () => import("./../../../src/pages/components/divider/android.mdx" /* webpackChunkName: "component---src-pages-components-divider-android-mdx" */),
  "component---src-pages-components-divider-guideline-mdx": () => import("./../../../src/pages/components/divider/guideline.mdx" /* webpackChunkName: "component---src-pages-components-divider-guideline-mdx" */),
  "component---src-pages-components-divider-ios-mdx": () => import("./../../../src/pages/components/divider/ios.mdx" /* webpackChunkName: "component---src-pages-components-divider-ios-mdx" */),
  "component---src-pages-components-divider-website-mdx": () => import("./../../../src/pages/components/divider/website.mdx" /* webpackChunkName: "component---src-pages-components-divider-website-mdx" */),
  "component---src-pages-components-dropdown-android-compose-mdx": () => import("./../../../src/pages/components/dropdown/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-android-compose-mdx" */),
  "component---src-pages-components-dropdown-guideline-mdx": () => import("./../../../src/pages/components/dropdown/guideline.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-guideline-mdx" */),
  "component---src-pages-components-dropdown-website-mdx": () => import("./../../../src/pages/components/dropdown/website.mdx" /* webpackChunkName: "component---src-pages-components-dropdown-website-mdx" */),
  "component---src-pages-components-dynamicicon-website-mdx": () => import("./../../../src/pages/components/dynamicicon/website.mdx" /* webpackChunkName: "component---src-pages-components-dynamicicon-website-mdx" */),
  "component---src-pages-components-fileupload-guideline-mdx": () => import("./../../../src/pages/components/fileupload/guideline.mdx" /* webpackChunkName: "component---src-pages-components-fileupload-guideline-mdx" */),
  "component---src-pages-components-fileupload-website-mdx": () => import("./../../../src/pages/components/fileupload/website.mdx" /* webpackChunkName: "component---src-pages-components-fileupload-website-mdx" */),
  "component---src-pages-components-flex-website-mdx": () => import("./../../../src/pages/components/flex/website.mdx" /* webpackChunkName: "component---src-pages-components-flex-website-mdx" */),
  "component---src-pages-components-grid-website-mdx": () => import("./../../../src/pages/components/grid/website.mdx" /* webpackChunkName: "component---src-pages-components-grid-website-mdx" */),
  "component---src-pages-components-image-android-mdx": () => import("./../../../src/pages/components/image/android.mdx" /* webpackChunkName: "component---src-pages-components-image-android-mdx" */),
  "component---src-pages-components-image-guideline-mdx": () => import("./../../../src/pages/components/image/guideline.mdx" /* webpackChunkName: "component---src-pages-components-image-guideline-mdx" */),
  "component---src-pages-components-image-upload-android-mdx": () => import("./../../../src/pages/components/imageUpload/android.mdx" /* webpackChunkName: "component---src-pages-components-image-upload-android-mdx" */),
  "component---src-pages-components-image-website-mdx": () => import("./../../../src/pages/components/image/website.mdx" /* webpackChunkName: "component---src-pages-components-image-website-mdx" */),
  "component---src-pages-components-list-website-mdx": () => import("./../../../src/pages/components/list/website.mdx" /* webpackChunkName: "component---src-pages-components-list-website-mdx" */),
  "component---src-pages-components-megamenu-guideline-mdx": () => import("./../../../src/pages/components/megamenu/guideline.mdx" /* webpackChunkName: "component---src-pages-components-megamenu-guideline-mdx" */),
  "component---src-pages-components-megamenu-website-mdx": () => import("./../../../src/pages/components/megamenu/website.mdx" /* webpackChunkName: "component---src-pages-components-megamenu-website-mdx" */),
  "component---src-pages-components-modal-android-compose-mdx": () => import("./../../../src/pages/components/modal/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-modal-android-compose-mdx" */),
  "component---src-pages-components-modal-android-xml-mdx": () => import("./../../../src/pages/components/modal/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-modal-android-xml-mdx" */),
  "component---src-pages-components-modal-guideline-mdx": () => import("./../../../src/pages/components/modal/guideline.mdx" /* webpackChunkName: "component---src-pages-components-modal-guideline-mdx" */),
  "component---src-pages-components-modal-website-mdx": () => import("./../../../src/pages/components/modal/website.mdx" /* webpackChunkName: "component---src-pages-components-modal-website-mdx" */),
  "component---src-pages-components-navigationbar-android-mdx": () => import("./../../../src/pages/components/navigationbar/android.mdx" /* webpackChunkName: "component---src-pages-components-navigationbar-android-mdx" */),
  "component---src-pages-components-navigationbar-guideline-mdx": () => import("./../../../src/pages/components/navigationbar/guideline.mdx" /* webpackChunkName: "component---src-pages-components-navigationbar-guideline-mdx" */),
  "component---src-pages-components-navigationbar-website-mdx": () => import("./../../../src/pages/components/navigationbar/website.mdx" /* webpackChunkName: "component---src-pages-components-navigationbar-website-mdx" */),
  "component---src-pages-components-overview-mdx": () => import("./../../../src/pages/components/overview.mdx" /* webpackChunkName: "component---src-pages-components-overview-mdx" */),
  "component---src-pages-components-pagination-guideline-mdx": () => import("./../../../src/pages/components/pagination/guideline.mdx" /* webpackChunkName: "component---src-pages-components-pagination-guideline-mdx" */),
  "component---src-pages-components-pagination-website-mdx": () => import("./../../../src/pages/components/pagination/website.mdx" /* webpackChunkName: "component---src-pages-components-pagination-website-mdx" */),
  "component---src-pages-components-pininput-android-compose-mdx": () => import("./../../../src/pages/components/pininput/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-pininput-android-compose-mdx" */),
  "component---src-pages-components-pininput-guideline-mdx": () => import("./../../../src/pages/components/pininput/guideline.mdx" /* webpackChunkName: "component---src-pages-components-pininput-guideline-mdx" */),
  "component---src-pages-components-pininput-website-mdx": () => import("./../../../src/pages/components/pininput/website.mdx" /* webpackChunkName: "component---src-pages-components-pininput-website-mdx" */),
  "component---src-pages-components-progressbar-guideline-mdx": () => import("./../../../src/pages/components/progressbar/guideline.mdx" /* webpackChunkName: "component---src-pages-components-progressbar-guideline-mdx" */),
  "component---src-pages-components-progressbar-website-mdx": () => import("./../../../src/pages/components/progressbar/website.mdx" /* webpackChunkName: "component---src-pages-components-progressbar-website-mdx" */),
  "component---src-pages-components-progresscircle-android-compose-mdx": () => import("./../../../src/pages/components/progresscircle/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-progresscircle-android-compose-mdx" */),
  "component---src-pages-components-progresscircle-guideline-mdx": () => import("./../../../src/pages/components/progresscircle/guideline.mdx" /* webpackChunkName: "component---src-pages-components-progresscircle-guideline-mdx" */),
  "component---src-pages-components-progresscircle-website-mdx": () => import("./../../../src/pages/components/progresscircle/website.mdx" /* webpackChunkName: "component---src-pages-components-progresscircle-website-mdx" */),
  "component---src-pages-components-radiobutton-android-compose-mdx": () => import("./../../../src/pages/components/radiobutton/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-radiobutton-android-compose-mdx" */),
  "component---src-pages-components-radiobutton-android-xml-mdx": () => import("./../../../src/pages/components/radiobutton/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-radiobutton-android-xml-mdx" */),
  "component---src-pages-components-radiobutton-guideline-mdx": () => import("./../../../src/pages/components/radiobutton/guideline.mdx" /* webpackChunkName: "component---src-pages-components-radiobutton-guideline-mdx" */),
  "component---src-pages-components-radiobutton-ios-mdx": () => import("./../../../src/pages/components/radiobutton/ios.mdx" /* webpackChunkName: "component---src-pages-components-radiobutton-ios-mdx" */),
  "component---src-pages-components-radiobutton-react-native-mdx": () => import("./../../../src/pages/components/radiobutton/react-native.mdx" /* webpackChunkName: "component---src-pages-components-radiobutton-react-native-mdx" */),
  "component---src-pages-components-radiobutton-website-mdx": () => import("./../../../src/pages/components/radiobutton/website.mdx" /* webpackChunkName: "component---src-pages-components-radiobutton-website-mdx" */),
  "component---src-pages-components-rating-android-compose-mdx": () => import("./../../../src/pages/components/rating/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-rating-android-compose-mdx" */),
  "component---src-pages-components-rating-guideline-mdx": () => import("./../../../src/pages/components/rating/guideline.mdx" /* webpackChunkName: "component---src-pages-components-rating-guideline-mdx" */),
  "component---src-pages-components-rating-website-mdx": () => import("./../../../src/pages/components/rating/website.mdx" /* webpackChunkName: "component---src-pages-components-rating-website-mdx" */),
  "component---src-pages-components-selectfield-guideline-mdx": () => import("./../../../src/pages/components/selectfield/guideline.mdx" /* webpackChunkName: "component---src-pages-components-selectfield-guideline-mdx" */),
  "component---src-pages-components-selectfield-react-native-mdx": () => import("./../../../src/pages/components/selectfield/react-native.mdx" /* webpackChunkName: "component---src-pages-components-selectfield-react-native-mdx" */),
  "component---src-pages-components-selectfield-website-mdx": () => import("./../../../src/pages/components/selectfield/website.mdx" /* webpackChunkName: "component---src-pages-components-selectfield-website-mdx" */),
  "component---src-pages-components-sidebar-guideline-mdx": () => import("./../../../src/pages/components/sidebar/guideline.mdx" /* webpackChunkName: "component---src-pages-components-sidebar-guideline-mdx" */),
  "component---src-pages-components-sidebar-website-mdx": () => import("./../../../src/pages/components/sidebar/website.mdx" /* webpackChunkName: "component---src-pages-components-sidebar-website-mdx" */),
  "component---src-pages-components-slider-android-compose-mdx": () => import("./../../../src/pages/components/slider/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-slider-android-compose-mdx" */),
  "component---src-pages-components-slider-guideline-mdx": () => import("./../../../src/pages/components/slider/guideline.mdx" /* webpackChunkName: "component---src-pages-components-slider-guideline-mdx" */),
  "component---src-pages-components-slider-react-native-mdx": () => import("./../../../src/pages/components/slider/react-native.mdx" /* webpackChunkName: "component---src-pages-components-slider-react-native-mdx" */),
  "component---src-pages-components-slider-website-mdx": () => import("./../../../src/pages/components/slider/website.mdx" /* webpackChunkName: "component---src-pages-components-slider-website-mdx" */),
  "component---src-pages-components-snackbar-android-compose-mdx": () => import("./../../../src/pages/components/snackbar/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-snackbar-android-compose-mdx" */),
  "component---src-pages-components-snackbar-android-xml-mdx": () => import("./../../../src/pages/components/snackbar/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-snackbar-android-xml-mdx" */),
  "component---src-pages-components-snackbar-guideline-mdx": () => import("./../../../src/pages/components/snackbar/guideline.mdx" /* webpackChunkName: "component---src-pages-components-snackbar-guideline-mdx" */),
  "component---src-pages-components-snackbar-ios-mdx": () => import("./../../../src/pages/components/snackbar/ios.mdx" /* webpackChunkName: "component---src-pages-components-snackbar-ios-mdx" */),
  "component---src-pages-components-snackbar-website-mdx": () => import("./../../../src/pages/components/snackbar/website.mdx" /* webpackChunkName: "component---src-pages-components-snackbar-website-mdx" */),
  "component---src-pages-components-spinner-android-compose-mdx": () => import("./../../../src/pages/components/spinner/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-spinner-android-compose-mdx" */),
  "component---src-pages-components-spinner-android-xml-mdx": () => import("./../../../src/pages/components/spinner/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-spinner-android-xml-mdx" */),
  "component---src-pages-components-spinner-guideline-mdx": () => import("./../../../src/pages/components/spinner/guideline.mdx" /* webpackChunkName: "component---src-pages-components-spinner-guideline-mdx" */),
  "component---src-pages-components-spinner-react-native-mdx": () => import("./../../../src/pages/components/spinner/react-native.mdx" /* webpackChunkName: "component---src-pages-components-spinner-react-native-mdx" */),
  "component---src-pages-components-spinner-website-mdx": () => import("./../../../src/pages/components/spinner/website.mdx" /* webpackChunkName: "component---src-pages-components-spinner-website-mdx" */),
  "component---src-pages-components-stepper-guideline-mdx": () => import("./../../../src/pages/components/stepper/guideline.mdx" /* webpackChunkName: "component---src-pages-components-stepper-guideline-mdx" */),
  "component---src-pages-components-stepper-website-mdx": () => import("./../../../src/pages/components/stepper/website.mdx" /* webpackChunkName: "component---src-pages-components-stepper-website-mdx" */),
  "component---src-pages-components-switch-android-mdx": () => import("./../../../src/pages/components/switch/android.mdx" /* webpackChunkName: "component---src-pages-components-switch-android-mdx" */),
  "component---src-pages-components-switch-guideline-mdx": () => import("./../../../src/pages/components/switch/guideline.mdx" /* webpackChunkName: "component---src-pages-components-switch-guideline-mdx" */),
  "component---src-pages-components-switch-ios-mdx": () => import("./../../../src/pages/components/switch/ios.mdx" /* webpackChunkName: "component---src-pages-components-switch-ios-mdx" */),
  "component---src-pages-components-switch-react-native-mdx": () => import("./../../../src/pages/components/switch/react-native.mdx" /* webpackChunkName: "component---src-pages-components-switch-react-native-mdx" */),
  "component---src-pages-components-switch-website-mdx": () => import("./../../../src/pages/components/switch/website.mdx" /* webpackChunkName: "component---src-pages-components-switch-website-mdx" */),
  "component---src-pages-components-tab-bar-android-mdx": () => import("./../../../src/pages/components/tabBar/android.mdx" /* webpackChunkName: "component---src-pages-components-tab-bar-android-mdx" */),
  "component---src-pages-components-tab-bar-guideline-mdx": () => import("./../../../src/pages/components/tabBar/guideline.mdx" /* webpackChunkName: "component---src-pages-components-tab-bar-guideline-mdx" */),
  "component---src-pages-components-tab-bar-website-mdx": () => import("./../../../src/pages/components/tabBar/website.mdx" /* webpackChunkName: "component---src-pages-components-tab-bar-website-mdx" */),
  "component---src-pages-components-table-guideline-mdx": () => import("./../../../src/pages/components/table/guideline.mdx" /* webpackChunkName: "component---src-pages-components-table-guideline-mdx" */),
  "component---src-pages-components-table-website-mdx": () => import("./../../../src/pages/components/table/website.mdx" /* webpackChunkName: "component---src-pages-components-table-website-mdx" */),
  "component---src-pages-components-textarea-android-compose-mdx": () => import("./../../../src/pages/components/textarea/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-textarea-android-compose-mdx" */),
  "component---src-pages-components-textarea-android-xml-mdx": () => import("./../../../src/pages/components/textarea/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-textarea-android-xml-mdx" */),
  "component---src-pages-components-textarea-guideline-mdx": () => import("./../../../src/pages/components/textarea/guideline.mdx" /* webpackChunkName: "component---src-pages-components-textarea-guideline-mdx" */),
  "component---src-pages-components-textarea-ios-mdx": () => import("./../../../src/pages/components/textarea/ios.mdx" /* webpackChunkName: "component---src-pages-components-textarea-ios-mdx" */),
  "component---src-pages-components-textarea-react-native-mdx": () => import("./../../../src/pages/components/textarea/react-native.mdx" /* webpackChunkName: "component---src-pages-components-textarea-react-native-mdx" */),
  "component---src-pages-components-textarea-website-mdx": () => import("./../../../src/pages/components/textarea/website.mdx" /* webpackChunkName: "component---src-pages-components-textarea-website-mdx" */),
  "component---src-pages-components-textfield-android-compose-mdx": () => import("./../../../src/pages/components/textfield/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-textfield-android-compose-mdx" */),
  "component---src-pages-components-textfield-android-xml-mdx": () => import("./../../../src/pages/components/textfield/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-textfield-android-xml-mdx" */),
  "component---src-pages-components-textfield-guideline-mdx": () => import("./../../../src/pages/components/textfield/guideline.mdx" /* webpackChunkName: "component---src-pages-components-textfield-guideline-mdx" */),
  "component---src-pages-components-textfield-ios-mdx": () => import("./../../../src/pages/components/textfield/ios.mdx" /* webpackChunkName: "component---src-pages-components-textfield-ios-mdx" */),
  "component---src-pages-components-textfield-ios-uikit-mdx": () => import("./../../../src/pages/components/textfield/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-components-textfield-ios-uikit-mdx" */),
  "component---src-pages-components-textfield-react-native-mdx": () => import("./../../../src/pages/components/textfield/react-native.mdx" /* webpackChunkName: "component---src-pages-components-textfield-react-native-mdx" */),
  "component---src-pages-components-textfield-website-mdx": () => import("./../../../src/pages/components/textfield/website.mdx" /* webpackChunkName: "component---src-pages-components-textfield-website-mdx" */),
  "component---src-pages-components-timepicker-guideline-mdx": () => import("./../../../src/pages/components/timepicker/guideline.mdx" /* webpackChunkName: "component---src-pages-components-timepicker-guideline-mdx" */),
  "component---src-pages-components-timepicker-website-mdx": () => import("./../../../src/pages/components/timepicker/website.mdx" /* webpackChunkName: "component---src-pages-components-timepicker-website-mdx" */),
  "component---src-pages-components-tooltip-android-compose-mdx": () => import("./../../../src/pages/components/tooltip/android-compose.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-android-compose-mdx" */),
  "component---src-pages-components-tooltip-android-xml-mdx": () => import("./../../../src/pages/components/tooltip/android-xml.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-android-xml-mdx" */),
  "component---src-pages-components-tooltip-guideline-mdx": () => import("./../../../src/pages/components/tooltip/guideline.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-guideline-mdx" */),
  "component---src-pages-components-tooltip-ios-mdx": () => import("./../../../src/pages/components/tooltip/ios.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-ios-mdx" */),
  "component---src-pages-components-tooltip-website-mdx": () => import("./../../../src/pages/components/tooltip/website.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-website-mdx" */),
  "component---src-pages-components-tree-guideline-mdx": () => import("./../../../src/pages/components/tree/guideline.mdx" /* webpackChunkName: "component---src-pages-components-tree-guideline-mdx" */),
  "component---src-pages-components-tree-website-mdx": () => import("./../../../src/pages/components/tree/website.mdx" /* webpackChunkName: "component---src-pages-components-tree-website-mdx" */),
  "component---src-pages-components-viewstate-android-mdx": () => import("./../../../src/pages/components/viewstate/android.mdx" /* webpackChunkName: "component---src-pages-components-viewstate-android-mdx" */),
  "component---src-pages-components-viewstate-react-native-mdx": () => import("./../../../src/pages/components/viewstate/react-native.mdx" /* webpackChunkName: "component---src-pages-components-viewstate-react-native-mdx" */),
  "component---src-pages-content-grammar-website-mdx": () => import("./../../../src/pages/content/grammar/website.mdx" /* webpackChunkName: "component---src-pages-content-grammar-website-mdx" */),
  "component---src-pages-content-mechanics-website-mdx": () => import("./../../../src/pages/content/mechanics/website.mdx" /* webpackChunkName: "component---src-pages-content-mechanics-website-mdx" */),
  "component---src-pages-content-overview-mdx": () => import("./../../../src/pages/content/overview.mdx" /* webpackChunkName: "component---src-pages-content-overview-mdx" */),
  "component---src-pages-content-writing-experience-mdx": () => import("./../../../src/pages/content/writing-experience.mdx" /* webpackChunkName: "component---src-pages-content-writing-experience-mdx" */),
  "component---src-pages-content-writing-experiences-component-mdx": () => import("./../../../src/pages/content/writing-experiences/component.mdx" /* webpackChunkName: "component---src-pages-content-writing-experiences-component-mdx" */),
  "component---src-pages-content-writing-experiences-pattern-mdx": () => import("./../../../src/pages/content/writing-experiences/pattern.mdx" /* webpackChunkName: "component---src-pages-content-writing-experiences-pattern-mdx" */),
  "component---src-pages-content-writing-guideline-mdx": () => import("./../../../src/pages/content/writing-guideline.mdx" /* webpackChunkName: "component---src-pages-content-writing-guideline-mdx" */),
  "component---src-pages-content-writing-guidelines-glossary-mdx": () => import("./../../../src/pages/content/writing-guidelines/glossary.mdx" /* webpackChunkName: "component---src-pages-content-writing-guidelines-glossary-mdx" */),
  "component---src-pages-content-writing-guidelines-guideline-mdx": () => import("./../../../src/pages/content/writing-guidelines/guideline.mdx" /* webpackChunkName: "component---src-pages-content-writing-guidelines-guideline-mdx" */),
  "component---src-pages-content-writing-patterns-website-mdx": () => import("./../../../src/pages/content/writing-patterns/website.mdx" /* webpackChunkName: "component---src-pages-content-writing-patterns-website-mdx" */),
  "component---src-pages-content-writing-principles-website-mdx": () => import("./../../../src/pages/content/writing-principles/website.mdx" /* webpackChunkName: "component---src-pages-content-writing-principles-website-mdx" */),
  "component---src-pages-contribute-and-request-index-mdx": () => import("./../../../src/pages/contribute-and-request/index.mdx" /* webpackChunkName: "component---src-pages-contribute-and-request-index-mdx" */),
  "component---src-pages-design-getting-started-avatar-profile-js": () => import("./../../../src/pages/design/getting-started/avatarProfile.js" /* webpackChunkName: "component---src-pages-design-getting-started-avatar-profile-js" */),
  "component---src-pages-design-getting-started-for-community-external-mdx": () => import("./../../../src/pages/design/getting-started/for-community-external.mdx" /* webpackChunkName: "component---src-pages-design-getting-started-for-community-external-mdx" */),
  "component---src-pages-design-getting-started-for-telkom-employee-mdx": () => import("./../../../src/pages/design/getting-started/for-telkom-employee.mdx" /* webpackChunkName: "component---src-pages-design-getting-started-for-telkom-employee-mdx" */),
  "component---src-pages-design-guideline-create-flow-mdx": () => import("./../../../src/pages/design/guideline/create-flow.mdx" /* webpackChunkName: "component---src-pages-design-guideline-create-flow-mdx" */),
  "component---src-pages-design-guideline-create-screen-mdx": () => import("./../../../src/pages/design/guideline/create-screen.mdx" /* webpackChunkName: "component---src-pages-design-guideline-create-screen-mdx" */),
  "component---src-pages-design-guideline-foundation-setup-mdx": () => import("./../../../src/pages/design/guideline/foundation-setup.mdx" /* webpackChunkName: "component---src-pages-design-guideline-foundation-setup-mdx" */),
  "component---src-pages-design-guideline-ui-kit-setup-mdx": () => import("./../../../src/pages/design/guideline/ui-kit-setup.mdx" /* webpackChunkName: "component---src-pages-design-guideline-ui-kit-setup-mdx" */),
  "component---src-pages-design-principles-mdx": () => import("./../../../src/pages/design/principles.mdx" /* webpackChunkName: "component---src-pages-design-principles-mdx" */),
  "component---src-pages-design-project-setup-mdx": () => import("./../../../src/pages/design/project-setup.mdx" /* webpackChunkName: "component---src-pages-design-project-setup-mdx" */),
  "component---src-pages-development-configuration-android-mdx": () => import("./../../../src/pages/development/configuration/android.mdx" /* webpackChunkName: "component---src-pages-development-configuration-android-mdx" */),
  "component---src-pages-development-configuration-ios-mdx": () => import("./../../../src/pages/development/configuration/ios.mdx" /* webpackChunkName: "component---src-pages-development-configuration-ios-mdx" */),
  "component---src-pages-development-configuration-website-mdx": () => import("./../../../src/pages/development/configuration/website.mdx" /* webpackChunkName: "component---src-pages-development-configuration-website-mdx" */),
  "component---src-pages-development-contribution-mdx": () => import("./../../../src/pages/development/contribution.mdx" /* webpackChunkName: "component---src-pages-development-contribution-mdx" */),
  "component---src-pages-development-installation-android-mdx": () => import("./../../../src/pages/development/installation/android.mdx" /* webpackChunkName: "component---src-pages-development-installation-android-mdx" */),
  "component---src-pages-development-installation-ios-mdx": () => import("./../../../src/pages/development/installation/ios.mdx" /* webpackChunkName: "component---src-pages-development-installation-ios-mdx" */),
  "component---src-pages-development-installation-react-native-mdx": () => import("./../../../src/pages/development/installation/react-native.mdx" /* webpackChunkName: "component---src-pages-development-installation-react-native-mdx" */),
  "component---src-pages-development-installation-website-mdx": () => import("./../../../src/pages/development/installation/website.mdx" /* webpackChunkName: "component---src-pages-development-installation-website-mdx" */),
  "component---src-pages-foundation-accessibility-mdx": () => import("./../../../src/pages/foundation/accessibility.mdx" /* webpackChunkName: "component---src-pages-foundation-accessibility-mdx" */),
  "component---src-pages-foundation-color-system-android-compose-mdx": () => import("./../../../src/pages/foundation/color-system/android-compose.mdx" /* webpackChunkName: "component---src-pages-foundation-color-system-android-compose-mdx" */),
  "component---src-pages-foundation-color-system-android-xml-mdx": () => import("./../../../src/pages/foundation/color-system/android-xml.mdx" /* webpackChunkName: "component---src-pages-foundation-color-system-android-xml-mdx" */),
  "component---src-pages-foundation-color-system-guideline-mdx": () => import("./../../../src/pages/foundation/color-system/guideline.mdx" /* webpackChunkName: "component---src-pages-foundation-color-system-guideline-mdx" */),
  "component---src-pages-foundation-color-system-ios-swiftui-mdx": () => import("./../../../src/pages/foundation/color-system/ios-swiftui.mdx" /* webpackChunkName: "component---src-pages-foundation-color-system-ios-swiftui-mdx" */),
  "component---src-pages-foundation-color-system-ios-uikit-mdx": () => import("./../../../src/pages/foundation/color-system/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-foundation-color-system-ios-uikit-mdx" */),
  "component---src-pages-foundation-color-system-website-mdx": () => import("./../../../src/pages/foundation/color-system/website.mdx" /* webpackChunkName: "component---src-pages-foundation-color-system-website-mdx" */),
  "component---src-pages-foundation-elevation-android-compose-mdx": () => import("./../../../src/pages/foundation/elevation/android-compose.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-android-compose-mdx" */),
  "component---src-pages-foundation-elevation-android-xml-mdx": () => import("./../../../src/pages/foundation/elevation/android-xml.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-android-xml-mdx" */),
  "component---src-pages-foundation-elevation-guideline-mdx": () => import("./../../../src/pages/foundation/elevation/guideline.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-guideline-mdx" */),
  "component---src-pages-foundation-elevation-ios-mdx": () => import("./../../../src/pages/foundation/elevation/ios.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-ios-mdx" */),
  "component---src-pages-foundation-elevation-website-mdx": () => import("./../../../src/pages/foundation/elevation/website.mdx" /* webpackChunkName: "component---src-pages-foundation-elevation-website-mdx" */),
  "component---src-pages-foundation-overview-mdx": () => import("./../../../src/pages/foundation/overview.mdx" /* webpackChunkName: "component---src-pages-foundation-overview-mdx" */),
  "component---src-pages-foundation-radius-android-compose-mdx": () => import("./../../../src/pages/foundation/radius/android-compose.mdx" /* webpackChunkName: "component---src-pages-foundation-radius-android-compose-mdx" */),
  "component---src-pages-foundation-radius-android-xml-mdx": () => import("./../../../src/pages/foundation/radius/android-xml.mdx" /* webpackChunkName: "component---src-pages-foundation-radius-android-xml-mdx" */),
  "component---src-pages-foundation-radius-guideline-mdx": () => import("./../../../src/pages/foundation/radius/guideline.mdx" /* webpackChunkName: "component---src-pages-foundation-radius-guideline-mdx" */),
  "component---src-pages-foundation-radius-ios-swiftui-mdx": () => import("./../../../src/pages/foundation/radius/ios-swiftui.mdx" /* webpackChunkName: "component---src-pages-foundation-radius-ios-swiftui-mdx" */),
  "component---src-pages-foundation-radius-ios-uikit-mdx": () => import("./../../../src/pages/foundation/radius/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-foundation-radius-ios-uikit-mdx" */),
  "component---src-pages-foundation-radius-website-mdx": () => import("./../../../src/pages/foundation/radius/website.mdx" /* webpackChunkName: "component---src-pages-foundation-radius-website-mdx" */),
  "component---src-pages-foundation-spacer-android-compose-mdx": () => import("./../../../src/pages/foundation/spacer/android-compose.mdx" /* webpackChunkName: "component---src-pages-foundation-spacer-android-compose-mdx" */),
  "component---src-pages-foundation-spacer-android-xml-mdx": () => import("./../../../src/pages/foundation/spacer/android-xml.mdx" /* webpackChunkName: "component---src-pages-foundation-spacer-android-xml-mdx" */),
  "component---src-pages-foundation-spacer-guideline-mdx": () => import("./../../../src/pages/foundation/spacer/guideline.mdx" /* webpackChunkName: "component---src-pages-foundation-spacer-guideline-mdx" */),
  "component---src-pages-foundation-spacer-website-mdx": () => import("./../../../src/pages/foundation/spacer/website.mdx" /* webpackChunkName: "component---src-pages-foundation-spacer-website-mdx" */),
  "component---src-pages-foundation-typography-android-compose-mdx": () => import("./../../../src/pages/foundation/typography/android-compose.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-android-compose-mdx" */),
  "component---src-pages-foundation-typography-android-xml-mdx": () => import("./../../../src/pages/foundation/typography/android-xml.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-android-xml-mdx" */),
  "component---src-pages-foundation-typography-guideline-mdx": () => import("./../../../src/pages/foundation/typography/guideline.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-guideline-mdx" */),
  "component---src-pages-foundation-typography-ios-swiftui-mdx": () => import("./../../../src/pages/foundation/typography/ios-swiftui.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-ios-swiftui-mdx" */),
  "component---src-pages-foundation-typography-ios-uikit-mdx": () => import("./../../../src/pages/foundation/typography/ios-uikit.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-ios-uikit-mdx" */),
  "component---src-pages-foundation-typography-react-native-mdx": () => import("./../../../src/pages/foundation/typography/react-native.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-react-native-mdx" */),
  "component---src-pages-foundation-typography-website-mdx": () => import("./../../../src/pages/foundation/typography/website.mdx" /* webpackChunkName: "component---src-pages-foundation-typography-website-mdx" */),
  "component---src-pages-illustrations-antares-eazy-index-mdx": () => import("./../../../src/pages/illustrations/antares-eazy/index.mdx" /* webpackChunkName: "component---src-pages-illustrations-antares-eazy-index-mdx" */),
  "component---src-pages-illustrations-character-guidance-mdx": () => import("./../../../src/pages/illustrations/character/guidance.mdx" /* webpackChunkName: "component---src-pages-illustrations-character-guidance-mdx" */),
  "component---src-pages-illustrations-character-usage-mdx": () => import("./../../../src/pages/illustrations/character/usage.mdx" /* webpackChunkName: "component---src-pages-illustrations-character-usage-mdx" */),
  "component---src-pages-illustrations-components-masonry-grid-jsx": () => import("./../../../src/pages/illustrations/components/MasonryGrid.jsx" /* webpackChunkName: "component---src-pages-illustrations-components-masonry-grid-jsx" */),
  "component---src-pages-illustrations-graphicon-guideline-mdx": () => import("./../../../src/pages/illustrations/graphicon/guideline.mdx" /* webpackChunkName: "component---src-pages-illustrations-graphicon-guideline-mdx" */),
  "component---src-pages-illustrations-graphicon-usage-mdx": () => import("./../../../src/pages/illustrations/graphicon/usage.mdx" /* webpackChunkName: "component---src-pages-illustrations-graphicon-usage-mdx" */),
  "component---src-pages-illustrations-guideline-guideline-mdx": () => import("./../../../src/pages/illustrations/guideline/guideline.mdx" /* webpackChunkName: "component---src-pages-illustrations-guideline-guideline-mdx" */),
  "component---src-pages-illustrations-guideline-principles-mdx": () => import("./../../../src/pages/illustrations/guideline/principles.mdx" /* webpackChunkName: "component---src-pages-illustrations-guideline-principles-mdx" */),
  "component---src-pages-illustrations-guideline-usage-mdx": () => import("./../../../src/pages/illustrations/guideline/usage.mdx" /* webpackChunkName: "component---src-pages-illustrations-guideline-usage-mdx" */),
  "component---src-pages-illustrations-mytens-index-mdx": () => import("./../../../src/pages/illustrations/mytens/index.mdx" /* webpackChunkName: "component---src-pages-illustrations-mytens-index-mdx" */),
  "component---src-pages-illustrations-overview-mdx": () => import("./../../../src/pages/illustrations/overview.mdx" /* webpackChunkName: "component---src-pages-illustrations-overview-mdx" */),
  "component---src-pages-illustrations-pijar-belajar-index-mdx": () => import("./../../../src/pages/illustrations/pijar-belajar/index.mdx" /* webpackChunkName: "component---src-pages-illustrations-pijar-belajar-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-pattern-component-pattern-iframe-jsx": () => import("./../../../src/pages/pattern/component/PatternIframe.jsx" /* webpackChunkName: "component---src-pages-pattern-component-pattern-iframe-jsx" */),
  "component---src-pages-pattern-dashboard-guideline-mdx": () => import("./../../../src/pages/pattern/dashboard/guideline.mdx" /* webpackChunkName: "component---src-pages-pattern-dashboard-guideline-mdx" */),
  "component---src-pages-pattern-dashboard-use-case-mdx": () => import("./../../../src/pages/pattern/dashboard/use-case.mdx" /* webpackChunkName: "component---src-pages-pattern-dashboard-use-case-mdx" */),
  "component---src-pages-pattern-dashboard-visualization-mdx": () => import("./../../../src/pages/pattern/dashboard/visualization.mdx" /* webpackChunkName: "component---src-pages-pattern-dashboard-visualization-mdx" */),
  "component---src-pages-pattern-landing-page-guideline-mdx": () => import("./../../../src/pages/pattern/landing-page/guideline.mdx" /* webpackChunkName: "component---src-pages-pattern-landing-page-guideline-mdx" */),
  "component---src-pages-pattern-login-and-register-guideline-mdx": () => import("./../../../src/pages/pattern/login-and-register/guideline.mdx" /* webpackChunkName: "component---src-pages-pattern-login-and-register-guideline-mdx" */),
  "component---src-pages-pattern-login-and-register-mobile-mdx": () => import("./../../../src/pages/pattern/login-and-register/mobile.mdx" /* webpackChunkName: "component---src-pages-pattern-login-and-register-mobile-mdx" */),
  "component---src-pages-pattern-login-and-register-website-mdx": () => import("./../../../src/pages/pattern/login-and-register/website.mdx" /* webpackChunkName: "component---src-pages-pattern-login-and-register-website-mdx" */),
  "component---src-pages-pattern-onboarding-mobile-mdx": () => import("./../../../src/pages/pattern/onboarding/mobile.mdx" /* webpackChunkName: "component---src-pages-pattern-onboarding-mobile-mdx" */),
  "component---src-pages-pattern-onboarding-website-mdx": () => import("./../../../src/pages/pattern/onboarding/website.mdx" /* webpackChunkName: "component---src-pages-pattern-onboarding-website-mdx" */),
  "component---src-pages-pattern-overview-mdx": () => import("./../../../src/pages/pattern/overview.mdx" /* webpackChunkName: "component---src-pages-pattern-overview-mdx" */),
  "component---src-pages-pattern-user-management-guideline-mdx": () => import("./../../../src/pages/pattern/user-management/guideline.mdx" /* webpackChunkName: "component---src-pages-pattern-user-management-guideline-mdx" */),
  "component---src-pages-pattern-user-management-use-case-mdx": () => import("./../../../src/pages/pattern/user-management/use-case.mdx" /* webpackChunkName: "component---src-pages-pattern-user-management-use-case-mdx" */),
  "component---src-pages-privacy-policy-index-mdx": () => import("./../../../src/pages/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-index-mdx" */),
  "component---src-pages-release-notes-android-mdx": () => import("./../../../src/pages/release-notes/android.mdx" /* webpackChunkName: "component---src-pages-release-notes-android-mdx" */),
  "component---src-pages-release-notes-ios-mdx": () => import("./../../../src/pages/release-notes/ios.mdx" /* webpackChunkName: "component---src-pages-release-notes-ios-mdx" */),
  "component---src-pages-release-notes-react-native-mdx": () => import("./../../../src/pages/release-notes/react-native.mdx" /* webpackChunkName: "component---src-pages-release-notes-react-native-mdx" */),
  "component---src-pages-release-notes-website-mdx": () => import("./../../../src/pages/release-notes/website.mdx" /* webpackChunkName: "component---src-pages-release-notes-website-mdx" */),
  "component---src-pages-test-spacing-audit-mdx": () => import("./../../../src/pages/test/spacing-audit.mdx" /* webpackChunkName: "component---src-pages-test-spacing-audit-mdx" */),
  "component---src-pages-theme-generator-generator-jsx": () => import("./../../../src/pages/theme-generator/Generator.jsx" /* webpackChunkName: "component---src-pages-theme-generator-generator-jsx" */),
  "component---src-pages-theme-generator-index-mdx": () => import("./../../../src/pages/theme-generator/index.mdx" /* webpackChunkName: "component---src-pages-theme-generator-index-mdx" */)
}

