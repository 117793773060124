import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import MasonryGrid from '../components/MasonryGrid.jsx';
import { overviewIllustration } from "./../../../components/constant";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Pijar Belajar`}</h2>
    <MasonryGrid brandAsset={overviewIllustration[0]?.assets} mdxType="MasonryGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      