import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Typography is defined as the science of style, appearance, and structure of fonts that aim to provide convenience and aesthetics to the reader.
Effective typography depends not only on the content but also on the presentation visual of the typography itself, starting from the font type, font size and line height.
You can chek out the guideline for more detail description.`}</p>
    <p>{`To get started, simply import the theme and apply the font to your UI components:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import ThemeLGNUIKit

let label = UILabel()
label.font = LGNUIFont.bodyLargeRegular
`}</code></pre>
    <div className="divi" />
    <h2>{`Heading`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Hero section, Title content or component, Subtitle of title`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading1'>{`Heading 1`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34pt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading2'>{`Heading 2`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28pt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading3'>{`Heading 3`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24pt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading4'>{`Heading 4`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22pt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading5'>{`Heading 5`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20pt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading6'>{`Heading 6`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18pt`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIFont.heading1
LGNUIFont.heading2
LGNUIFont.heading3
LGNUIFont.heading4
LGNUIFont.heading5
LGNUIFont.heading6
`}</code></pre>
    <div className="divi" />
    <h2>{`Body`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Paragraph, Button, Text Field, Tabs, etc`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Style`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeReguler'>{`Large Regular`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeSemibold'>{`Large Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Semibold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeBold'>{`Large Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeItalic'>{`Large Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italic`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallReguler'>{`Small Regular`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallSemibold'>{`Small Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Semibold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallBold'>{`Small Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallItalic'>{`Small Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italic`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIFont.bodyLargeRegular
LGNUIFont.bodyLargeSemiBold
LGNUIFont.bodyLargeBold
LGNUIFont.bodyLargeItalic
LGNUIFont.bodySmallRegular
LGNUIFont.bodySmallSemiBold
LGNUIFont.bodySmallBold
LGNUIFont.bodySmallItalic
`}</code></pre>
    <div className="divi" />
    <h2>{`Caption`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Tags, Chips, Badge, etc`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Style`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallReguler'>{`Small Regular`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallSemibold'>{`Small Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Semibold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallBold'>{`Small Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallItalic'>{`Small Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11pt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italic`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIFont.bodyCaptionRegular
LGNUIFont.bodyCaptionSemiBold
LGNUIFont.bodyCaptionBold
LGNUIFont.bodyCaptionItalic
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      