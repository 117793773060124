import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";

import * as styles from "./MasonryGrid.module.scss";

const MasonryGrid = ({ brandAsset = [] }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null; // Render nothing on the server
  }

  return (
    <div className={styles.gridContainer}>
      <Masonry
        breakpointCols={{ 500: 1, 800: 2, 1200: 3 }}
        className={styles.masonryGrid}
        columnClassName={styles.masonryColumn}
      >
        {brandAsset.map((image) => (
          <div key={image.id} className={styles.gridItem}>
            <img src={image.src} alt={image.alt} className={styles.image} />
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default MasonryGrid;