import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ColorsCard from "../../../components/CardColor/CardColor.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Usage`}</h2>
    <p>{`Colors system leverages a purposeful set of color styles as the perfect starting point for any brand or project.
When it comes to color, contrast is critical for ensuring text is legible.`}</p>
    <p>{`To get started, simply import the theme and apply the colors to your UI components:`}</p>
    <pre><code parentName="pre" {...{}}>{`import ThemeLGNUIKit

let uiView = UIView()
uiView.backgroundColor = LGNUIColor.primary100
`}</code></pre>
    <div className="divi" />
    <h2>{`Primary Colors`}</h2>
    <p>{`The primary color is your “brand” color, and is used across all interactive elements such as buttons, links,
inputs, etc. This color can define the overall feel and can elicit emotion.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="primary25" mdxType="ColorsCard" />
  <ColorsCard variant="primary50" mdxType="ColorsCard" />
  <ColorsCard variant="primary100" mdxType="ColorsCard" />
  <ColorsCard variant="primary200" mdxType="ColorsCard" />
  <ColorsCard variant="primary300" mdxType="ColorsCard" />
  <ColorsCard variant="primary400" mdxType="ColorsCard" />
  <ColorsCard variant="primary500" mdxType="ColorsCard" />
  <ColorsCard variant="primary600" mdxType="ColorsCard" />
  <ColorsCard variant="primary700" mdxType="ColorsCard" />
  <ColorsCard variant="primary800" mdxType="ColorsCard" />
  <ColorsCard variant="primary900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.primary25
LGNUIColor.primary50
LGNUIColor.primary100
LGNUIColor.primary200
LGNUIColor.primary300
LGNUIColor.primary400
LGNUIColor.primary500
LGNUIColor.primary600
LGNUIColor.primary700
LGNUIColor.primary800
LGNUIColor.primary900
`}</code></pre>
    <br />
    <br />
    <h2>{`Secondary Colors`}</h2>
    <p>{`The secondary colors is color highlight or complement the primary color.
These are to be used sparingly to make the UI elements stand out. These colors are also usually defined in the brand guidelines.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="secondary25" mdxType="ColorsCard" />
  <ColorsCard variant="secondary50" mdxType="ColorsCard" />
  <ColorsCard variant="secondary100" mdxType="ColorsCard" />
  <ColorsCard variant="secondary200" mdxType="ColorsCard" />
  <ColorsCard variant="secondary300" mdxType="ColorsCard" />
  <ColorsCard variant="secondary400" mdxType="ColorsCard" />
  <ColorsCard variant="secondary500" mdxType="ColorsCard" />
  <ColorsCard variant="secondary600" mdxType="ColorsCard" />
  <ColorsCard variant="secondary700" mdxType="ColorsCard" />
  <ColorsCard variant="secondary800" mdxType="ColorsCard" />
  <ColorsCard variant="secondary900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.secondary25
LGNUIColor.secondary50
LGNUIColor.secondary100
LGNUIColor.secondary200
LGNUIColor.secondary300
LGNUIColor.secondary400
LGNUIColor.secondary500
LGNUIColor.secondary600
LGNUIColor.secondary700
LGNUIColor.secondary800
LGNUIColor.secondary900
`}</code></pre>
    <br />
    <br />
    <h2>{`Tertiary Colors`}</h2>
    <p>{`The tertiary color is a neutral color and is the foundation of the color system.
Almost everything in UI design — text, form fields, backgrounds, dividers, outline card — are usually gray.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="tertiary25" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary50" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary100" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary200" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary300" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary400" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary500" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary600" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary700" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary800" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.tertiary25
LGNUIColor.tertiary50
LGNUIColor.tertiary100
LGNUIColor.tertiary200
LGNUIColor.tertiary300
LGNUIColor.tertiary400
LGNUIColor.tertiary500
LGNUIColor.tertiary600
LGNUIColor.tertiary700
LGNUIColor.tertiary800
LGNUIColor.tertiary900
`}</code></pre>
    <br />
    <br />
    <h2>{`Semantic Colors`}</h2>
    <p>{`Semantic colours communicate important status to the user such as success, information,
warning or error. These colours should be used paired with text, an icon or both to
fulfill their purpose in the best way possible.`}</p>
    <h2>{`Success`}</h2>
    <p>{`Success colors communicate a positive action, postive trend, or a successful confirmation.
If you’re using green as your primary color, it can be helpful to introduce a different
hue for your success green.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="success25" mdxType="ColorsCard" />
  <ColorsCard variant="success50" mdxType="ColorsCard" />
  <ColorsCard variant="success100" mdxType="ColorsCard" />
  <ColorsCard variant="success200" mdxType="ColorsCard" />
  <ColorsCard variant="success300" mdxType="ColorsCard" />
  <ColorsCard variant="success400" mdxType="ColorsCard" />
  <ColorsCard variant="success500" mdxType="ColorsCard" />
  <ColorsCard variant="success600" mdxType="ColorsCard" />
  <ColorsCard variant="success700" mdxType="ColorsCard" />
  <ColorsCard variant="success800" mdxType="ColorsCard" />
  <ColorsCard variant="success900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.success25
LGNUIColor.success50
LGNUIColor.success100
LGNUIColor.success200
LGNUIColor.success300
LGNUIColor.success400
LGNUIColor.success500
LGNUIColor.success600
LGNUIColor.success700
LGNUIColor.success800
LGNUIColor.success900
`}</code></pre>
    <br />
    <br />
    <h3>{`Warning`}</h3>
    <p>{`Warning colors can communicate that an action is potentially destructive or “on-hold”.
These colors are commonly used in confirmations to grab the users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="warning25" mdxType="ColorsCard" />
  <ColorsCard variant="warning50" mdxType="ColorsCard" />
  <ColorsCard variant="warning100" mdxType="ColorsCard" />
  <ColorsCard variant="warning200" mdxType="ColorsCard" />
  <ColorsCard variant="warning300" mdxType="ColorsCard" />
  <ColorsCard variant="warning400" mdxType="ColorsCard" />
  <ColorsCard variant="warning500" mdxType="ColorsCard" />
  <ColorsCard variant="warning600" mdxType="ColorsCard" />
  <ColorsCard variant="warning700" mdxType="ColorsCard" />
  <ColorsCard variant="warning800" mdxType="ColorsCard" />
  <ColorsCard variant="warning900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.warning25
LGNUIColor.warning50
LGNUIColor.warning100
LGNUIColor.warning200
LGNUIColor.warning300
LGNUIColor.warning400
LGNUIColor.warning500
LGNUIColor.warning600
LGNUIColor.warning700
LGNUIColor.warning800
LGNUIColor.warning900
`}</code></pre>
    <br />
    <br />
    <h3>{`Information`}</h3>
    <p>{`Infomation colors can communicate that an action is informative section or`}<br parentName="p"></br>{`
`}{`these colors are commonly used in awareness for users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="information25" mdxType="ColorsCard" />
  <ColorsCard variant="information50" mdxType="ColorsCard" />
  <ColorsCard variant="information100" mdxType="ColorsCard" />
  <ColorsCard variant="information200" mdxType="ColorsCard" />
  <ColorsCard variant="information300" mdxType="ColorsCard" />
  <ColorsCard variant="information400" mdxType="ColorsCard" />
  <ColorsCard variant="information500" mdxType="ColorsCard" />
  <ColorsCard variant="information600" mdxType="ColorsCard" />
  <ColorsCard variant="information700" mdxType="ColorsCard" />
  <ColorsCard variant="information800" mdxType="ColorsCard" />
  <ColorsCard variant="information900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.information25
LGNUIColor.information50
LGNUIColor.information100
LGNUIColor.information200
LGNUIColor.information300
LGNUIColor.information400
LGNUIColor.information500
LGNUIColor.information600
LGNUIColor.information700
LGNUIColor.information800
LGNUIColor.information900
`}</code></pre>
    <br />
    <br />
    <h3>{`Error`}</h3>
    <p>{`Error colors are used across error states and in “destructive” actions.
They communicate a destructive/negative action, such as removing a user from your team.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="error25" mdxType="ColorsCard" />
  <ColorsCard variant="error50" mdxType="ColorsCard" />
  <ColorsCard variant="error100" mdxType="ColorsCard" />
  <ColorsCard variant="error200" mdxType="ColorsCard" />
  <ColorsCard variant="error300" mdxType="ColorsCard" />
  <ColorsCard variant="error400" mdxType="ColorsCard" />
  <ColorsCard variant="error500" mdxType="ColorsCard" />
  <ColorsCard variant="error600" mdxType="ColorsCard" />
  <ColorsCard variant="error700" mdxType="ColorsCard" />
  <ColorsCard variant="error800" mdxType="ColorsCard" />
  <ColorsCard variant="error900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNUIColor.error25
LGNUIColor.error50
LGNUIColor.error100
LGNUIColor.error200
LGNUIColor.error300
LGNUIColor.error400
LGNUIColor.error500
LGNUIColor.error600
LGNUIColor.error700
LGNUIColor.error800
LGNUIColor.error900
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      