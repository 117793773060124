import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This ensures that each icon not only aligns with Telkom Indonesia’s brand identity but also adheres to the illustration principles of Dynamic, Simple and Flexible, and Empowerment. By following these principles, the icons are designed to be visually cohesive, adaptable across different contexts, and accessible to a diverse audience. By applying these principles consistently, Telkom Indonesia sets high standard for its visual assets, fostering familiarity and trust while enhancing the overall user experience across its digital products.`}</p>
    <h2>{`Dynamic`}</h2>
    <p><strong parentName="p">{`The Dynamic principle`}</strong>{` is applied by selecting an illustration style that adapts seamlessly to various learning contexts,  allowing visual elements to be easily adjusted without losing their appeal. For example, the use of bright color palettes and simple shapes is designed to capture attention without disrupting the learning flow.`}</p>
    <div className="divi" />
    <h2>{`Simple & Flexible`}</h2>
    <p><strong parentName="p">{`The principles of Simple and Flexible`}</strong>{` also guide the creation of illustrations that are understated yet informative. Each visual element is carefully chosen to support user focus and enhance the understanding of the material.`}</p>
    <div className="divi" />
    <h2>{`Harmony`}</h2>
    <p>{`Lastly, `}<strong parentName="p">{`the Harmony principle`}</strong>{` ensurees alignment across design elements. By adhering to Consistency and Standards, every illustration component, from typography to iconography, is chosen to maintain uniformity across platforms. This creates a familiar user experience and minimizes potential confusion. All elements work together to support the primary goal: providing visuals that are not only engaging but also facilitate an intuitive and effective learning process.`}</p>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      