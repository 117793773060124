const overviewComponent = [
  {
    title: "Accordion",
    description:
      "An element that can expand/collapse to reveal or hide more associated content",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/accordion.svg",
    link: "/components/accordion/guideline",
    website: true,
    ios: true,
    android: true,
  },
  {
    title: "Alert",
    description:
      "To informing the user of important changes in a prominent way.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/alert.svg",
    link: "/components/alert/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Alert Dialog",
    description:
      "A popup dialog that informs the user of confirmation, warning, or error messages.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/alert.svg",
    link: "/components/alertdialog/react-native",
    reactNative: true,
  },
  {
    title: "Anchor",
    description:
      "Actionable text link trigger for user to navigate or use as tertiary call to action",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/anchor.svg",
    link: "/components/anchor/guideline",
    android: true,
    website: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Avatar",
    description:
      "Graphical interface to a visual representation of a user, entity or profile",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/avatar.svg",
    link: "/components/avatar/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Badge",
    description:
      "The visual indicator for the values such as labeling or notification",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/badge.svg",
    link: "/components/badge/guideline",
    website: true,
    android: true,
    ios: true,
  },
  {
    title: "Bottom Sheet",
    description: "The bottom sheet is a slides from the bottom of the screen",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/guideline/bottom-sheet-component-hero.svg",
    link: "/components/bottomsheet/guideline",
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Breadcrumb",
    description:
      "Showing users their position in a website or app's hierarchy for easy backtracking.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/breadcrumb.svg",
    link: "/components/breadcrumb/guideline",
    website: true,
  },

  {
    title: "Box",
    description:
      "Hold other elements, and can be styled to adjust its position, size, and layout",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/box.svg",
    link: "/components/box/website",
    website: true,
  },
  {
    title: "Button",
    description:
      "The visual indicator for the values such as labeling or notification",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/button.svg",
    link: "/components/button/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Card",
    description:
      "A container, wrap or shell for content representing a single entity",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/card.svg",
    link: "/components/card/guideline",
    website: true,
  },
  {
    title: "Checkbox",
    description:
      "Allow users to selected multiple optional items or selected option agreements",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/chekbox.svg",
    link: "/components/checkbox/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Chips",
    description:
      "Allow users to enter information, make selections, filter content, or trigger actions",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/chips.svg",
    link: "/components/chips/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Container",
    description:
      "A container component is a reusable UI element used to group and style other components or content within a web or application interface.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/container.svg",
    link: "/components/container/website",
    website: true,
  },
  {
    title: "Datepicker",
    description: "Allow users to pick date",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/datepicker.svg",
    link: "/components/datepicker/guideline",
    website: true,
  },
  {
    title: "Divider",
    description:
      "Line should be used to bring clarity and structure to a layout, section and content",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/divider.svg",
    link: "/components/divider/guideline",
    website: true,
    android: true,
    ios: true
  },
  {
    title: "Dropdown",
    description: "Selecting a value when expanded can shows a list option",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/dropdown.svg",
    link: "/components/dropdown/guideline",
    website: true,
  },
  {
    title: "File Upload",
    description:
      "Simplify file uploads with a user-friendly interface, making data transfer easy and efficient",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/file-uploader.svg",
    link: "/components/fileupload/guideline",
    website: true,
  },
  {
    title: "Flex",
    description:
      "Enables flexible layouts for its child elements, allowing for dynamic and responsive design",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/flex.svg",
    link: "/components/flex/website",
    website: true,
  },
  {
    title: "Image",
    description: "A visual representation of something",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/image.svg",
    link: "/components/image/guideline",
    website: true,
    android: true,
  },
  {
    title: "List",
    description:
      "Element for displaying and organizing information in an ordered or unordered list format",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/list.svg",
    link: "/components/list/website",
    website: true,
  },
  {
    title: "Modal",
    description:
      "A modal component is a pop-up overlay used to display additional information, forms, or actions without navigating away from the current page.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/modal/modal-hero.svg",
    link: "/components/modal/guideline",
    website: true,
    android: true,
  },
  {
    title: "Navigation Bar",
    description: "Navigation elements are displayed at the top of the screen",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/navigationbar.svg",
    link: "/components/navigationbar/guideline",
    website: true,
    android: true,
  },
  {
    title: "Pagination",
    description:
      "Dividing and navigating content into manageable pages, when dealing with large data sets",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/pagination.svg",
    link: "/components/pagination/guideline",
    website: true,
  },
  {
    title: "Pin Input",
    description: "Lorem ipsum dolor sit amet.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/pin-input.svg",
    link: "/components/pininput/guideline",
    website: true,
  },
  {
    title: "Progress Bar",
    description:
      "Component is a visual indicator that displays the progression of a task or process.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/progress-bar.svg",
    link: "/components/progressbar/guideline",
    website: true,
  },
  {
    title: "Progress Circle",
    description:
      "Used to visually represent the progress or completion status of a task",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/progress-circle.svg",
    link: "/components/progresscircle/guideline",
    website: true,
  },
  {
    title: "Radio Button",
    description: "For users select one option from list of group option",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/radiobutton.svg",
    link: "/components/radiobutton/guideline",
    ios: true,
    android: true,
    website: true,
    reactNative: true,
  },
  {
    title: "Rating",
    description:
      "User-friendly and versatile tool for rating and feedback, enhancing user interactions",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/rating.svg",
    link: "/components/rating/guideline",
    website: true,
  },
  {
    title: "Slider",
    description:
      "Intuitive and interactive UI element for selecting values, perfect for customization and engagement",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/slider.svg",
    link: "/components/slider/guideline",
    website: true,
    reactNative: true,
  },
  {
    title: "Snackbar",
    description:
      "Inform feedback to the users result of the process or action executed",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/snackbar.svg",
    link: "/components/snackbar/guideline",
    android: true,
    ios: true,
    website: true,
  },
  {
    title: "Select Field",
    description:
      "For selecting a value when expanded can shows a list of options",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/selectfield.svg",
    link: "/components/selectfield/guideline",
    website: true,
    reactNative: true
  },
  {
    title: "Sidebar",
    description: "Vertical side component that supports navigational elements",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/sidebar.svg",
    link: "/components/sidebar/website",
    website: true,
  },
  {
    title: "Spinner",
    description:
      "Animated spinning icon that lets users know content is being loaded",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/spin.svg",
    link: "/components/spinner/guideline",
    website: true,
    android: true,
    reactNative: true,
  },
  {
    title: "Stepper",
    description:
      "To visualize and guiding toward the completion of a specified process.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/stepper.svg",
    link: "/components/stepper/guideline",
    website: true,
  },
  {
    title: "Switch",
    description:
      "Allow users to offers a control to switch the toggle items setting on or off",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/switch.svg",
    link: "/components/switch/guideline",
    website: true,
    ios: true,
    android: true,
    reactNative: true,
  },
  {
    title: "Tab Bar",
    description:
      "To navigate between multiple panels, reducing clutter and fitting more into a smaller space.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/tabbar.svg",
    link: "/components/tabBar/guideline",
    website: true,
    android: true,
  },
  {
    title: "Table",
    description:
      "For displaying large amounts of data inside the rows and columns",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/table.svg",
    link: "/components/table/guideline",
    website: true,
  },
  {
    title: "Textarea",
    description:
      "Enable the user to interact with long form text which spans over multiple lines",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/area.svg",
    link: "/components/textarea/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Textfield",
    description:
      "Enable the user to interact with spesific input content, value or data",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/field.svg",
    link: "/components/textfield/guideline",
    website: true,
    android: true,
    ios: true,
    reactNative: true,
  },
  {
    title: "Time Picker",
    description:
      "To help and allows users to select a specific time format from field",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/time-picker.svg",
    link: "/components/timepicker/guideline",
    website: true,
  },
  {
    title: "Tooltip",
    description:
      "Shows additional context on tap, focus or hover to help give more information in the interaction",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/tooltip.svg",
    link: "/components/tooltip/guideline",
    website: true,
    android: true,
    ios: true,
  },
  {
    title: "Tree",
    description:
      "Hierarchical structure for organizing and simplifying complex information with interaction",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/tree.svg",
    link: "/components/tree/guideline",
    website: true,
  },
  {
    title: "View State",
    description:
      "Stores and updates data related to the current state of a view.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/component/viewstate.svg",
    link: "/components/viewstate/android",
    android: true,
    reactNative: true
  },
];

const overviewFoundation = [
  {
    title: "Accessibility",
    description:
      "Focuses on enabling access for inclusive people, enabling access through the use of technology",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/foundation/Access.svg",
    link: "/foundation/accessibility",
  },
  {
    title: "Color System",
    description:
      "Color distinguishes for brand and helps to create consistent experiences across products",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/foundation/color.svg",
    link: "/foundation/color-system/guideline",
  },
  {
    title: "Typography",
    description:
      "Typography is used critical for communicating the hierarchy content and cotext of a page",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/foundation/typo.svg",
    link: "/foundation/typography/guideline",
  },
  {
    title: "Spacer",
    description:
      "Horizontal and vertical distances between components or the viewpoints of the layout screen",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/foundation/Spacing.svg",
    link: "/foundation/spacer/guideline",
  },
  {
    title: "Radius",
    description:
      "Rounds the corners of element's or component outer border edge with impact to style of brand",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/foundation/Radius.svg",
    link: "/foundation/radius/website",
  },
  {
    title: "Elevation",
    description:
      "Visual cues in the interface which tell human brains what user interface elements looking at",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/foundation/Elevation.svg",
    link: "/foundation/elevation/website",
  },
];

const overviewPattern = [
  {
    title: "Dashboard",
    description:
      "General information management that receives data from a linked database to provide data visualizations.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/pattern/dashboard-overview.svg",
    link: "/pattern/dashboard/guideline",
    website: true,
  },
  {
    title: "Login & Register",
    description:
      "General login process flow such as the register page, forgot password and set new password",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/pattern/login-overview.svg",
    link: "/pattern/login-and-register/guideline",
    android: true,
    website: true,
  },
  {
    title: "On Boarding",
    description:
      "General on boarding process flow such as the splash screen page until the home or login page",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/pattern/onboarding-overview.svg",
    link: "/pattern/onboarding/mobile",
    android: true,
  },
];

const overviewContent = [
  {
    title: "Writing Principles",
    description:
      "Use these guidelines as a source of truth, for best practices and patterns when writing messages.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/content/writing-principle.svg",
    link: "/content/writing-principles/website",
  },
  {
    title: "Mechanics",
    description:
      "General on boarding process flow such as the splash screen page until the home or login page",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/content/mechanics.svg",
    link: "/content/mechanics/website",
  },
  {
    title: "Grammar",
    description:
      "Use these guidelines as a source of truth, for best practices and patterns when writing messages",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/overview/content/grammar.svg",
    link: "/content/grammar/website",
  },
];

const overviewIllustration = [
  {
    title: "Pijar Belajar",
    description: "Aplikasi Belajar Online Hemat dan Menyenangkan.",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/pijar-belajar-banner.png",
    link: "/illustrations/pijar-belajar",
    brand: "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/pijar-belajar-brand.svg",
    assets: [
      { id: 1, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/hero-1.webp', alt: 'Hero Illustration Pijar Belajar 1' },
      { id: 2, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/hero-2.webp', alt: 'Hero Illustration Pijar Belajar 2' },
      { id: 3, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/hero-3.webp', alt: 'Hero Illustration Pijar Belajar 3' },
      { id: 4, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-1.webp', alt: 'Spot Illustration Pijar Belajar 1' },
      { id: 5, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-2.webp', alt: 'Spot Illustration Pijar Belajar 2' },
      { id: 6, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-3.webp', alt: 'Spot Illustration Pijar Belajar 3' },
      { id: 7, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-4.webp', alt: 'Spot Illustration Pijar Belajar 4' },
      { id: 8, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-5.webp', alt: 'Spot Illustration Pijar Belajar 5' },
      { id: 9, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-6.webp', alt: 'Spot Illustration Pijar Belajar 6' },
      { id: 10, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-7.webp', alt: 'Spot Illustration Pijar Belajar 7' },
      { id: 11, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-8.webp', alt: 'Spot Illustration Pijar Belajar 8' },
      { id: 12, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-9.webp', alt: 'Spot Illustration Pijar Belajar 9' },
      { id: 13, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/spot-10.webp', alt: 'Spot Illustration Pijar Belajar 10' },
      { id: 14, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/footer-1.webp', alt: 'Foooter Illustration Pijar Belajar 1'},
      { id: 15, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/footer-2.webp', alt: 'Foooter Illustration Pijar Belajar 2'},
      { id: 16, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/footer-3.webp', alt: 'Foooter Illustration Pijar Belajar 3'},
      { id: 17, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-1.webp', alt: 'Icon Illustration Pijar Belajar 1'},
      { id: 18, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-2.webp', alt: 'Icon Illustration Pijar Belajar 2'},
      { id: 19, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-3.webp', alt: 'Icon Illustration Pijar Belajar 3'},
      { id: 20, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-4.webp', alt: 'Icon Illustration Pijar Belajar 4'},
      { id: 21, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-4a.webp', alt: 'Icon Illustration Pijar Belajar 5'},
      { id: 22, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-5.webp', alt: 'Icon Illustration Pijar Belajar 6'},
      { id: 23, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/icon-6.webp', alt: 'Icon Illustration Pijar Belajar 7'},
      { id: 24, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/pijar-belajar/state-illustration-1.webp', alt: 'State Illustration Pijar Belajar 1'},
    ],
  },
  {
    title: "Antares Eazy",
    description: "Smart Device & Home Security Terbaik",
    image:
      "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/antares-eazy-banner.png",
    link: "/illustrations/antares-eazy",
    brand: "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/antares-eazy-brand.svg",
    assets: [
      { id: 1, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/onboarding-screen-1.webp', alt: 'Onboarding Screen Antares Eazy 1' },
      { id: 2, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/onboarding-screen-2.webp', alt: 'Onboarding Screen Antares Eazy 2' },
      { id: 3, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/onboarding-screen-3.webp', alt: 'Onboarding Screen Antares Eazy 3' },
      { id: 4, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/onboarding-screen-4.webp', alt: 'Onboarding Screen Antares Eazy 4' },
      { id: 5, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/login-screen-1.webp', alt: 'Login Screen Antares Eazy 1' },
      { id: 6, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/login-screen-2.webp', alt: 'Login Screen Antares Eazy 2' },
      { id: 7, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/login-screen-3.webp', alt: 'Login Screen Antares Eazy 3' },
      { id: 8, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/login-screen-4.webp', alt: 'Login Screen Antares Eazy 4' },
      { id: 9, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-1.webp', alt: 'Device Antares Eazy 1' },
      { id: 10, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-2.webp', alt: 'Device Antares Eazy 2' },
      { id: 11, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-3.webp', alt: 'Device Antares Eazy 3' },
      { id: 12, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-4.webp', alt: 'Device Antares Eazy 4' },
      { id: 13, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-5.webp', alt: 'Device Antares Eazy 5' },
      { id: 14, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-6.webp', alt: 'Device Antares Eazy 6' },
      { id: 15, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-7.webp', alt: 'Device Antares Eazy 7' },
      { id: 16, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-8.webp', alt: 'Device Antares Eazy 8' },
      { id: 17, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/device-9.webp', alt: 'Device Antares Eazy 9' },
      { id: 18, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/icon-illustration-1.webp', alt: 'Icon Illustration 1' },
      { id: 19, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/antares-eazy/illustration-library-1.webp', alt: 'Illustration Library 1' },
    ],
  },
  {
    title: "MyTEnS",
    description: "Partner transformasi digital terbaik untuk Anda",
    image: "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-5.webp",
    link: "/illustrations/mytens",
    brand: "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/mytens-brand.svg",
    assets: [
      { id: 1, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/icon-1.webp', alt: 'Icon MyTEnS 1' },
      { id: 2, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/icon-2.webp', alt: 'Icon MyTEnS 2' },
      { id: 3, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/icon-3.webp', alt: 'Icon MyTEnS 3' },
      { id: 4, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/icon-4.webp', alt: 'Icon MyTenS 4' },
      { id: 5, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/icon-5.webp', alt: 'Icon MyTenS 5' },
      { id: 6, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/icon-pack-1.webp', alt: 'Icon Pack MyTEnS 1' },
      { id: 7, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/new-illustration-1.webp', alt: 'New Illustration MyTEnS 1' },
      { id: 8, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-1.webp', alt: 'Image Showcase MyTEnS 1' },
      { id: 9, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-2.webp', alt: 'Image Showcase MyTEnS 2' },
      { id: 10, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-3.webp', alt: 'Image Showcase MyTEnS 3' },
      { id: 11, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-4.webp', alt: 'Image Showcase MyTEnS 4' },
      { id: 12, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-5.webp', alt: 'Image Showcase MyTEnS 5' },
      { id: 13, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-6.webp', alt: 'Image Showcase MyTEnS 6' },
      { id: 14, src: 'https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/brands/mytens/showcase-7.webp', alt: 'Image Showcase MyTEnS 7' },
    ],
  },
]

export {
  overviewComponent,
  overviewContent,
  overviewFoundation,
  overviewIllustration,
  overviewPattern,
};
