import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Graphicon Guideline`}</strong>{`: This guideline presents various information and uses of graphicon based on concept and execution. Each section has Do(s) and Don’t(s) that you must read carefully.`}</p>
    </InlineNotification>
    <h2>{`Grid & Keyline`}</h2>
    <p>{`A grid is a box-shaped element or a line that helps each graphicon to have a consistent size on our worksheet.`}</p>
    <p>{`Meanwhile, the keyline is a guide to ensure the icon has uniform proportions, regardless of variations in its basic shape, such as square, circle, horizontal and vertical. By using a keyline, the graphicon will have consistent proportions.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/grid-n-keyline.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The grid we use has an adjustable size with a 1:1 ratio and has 2px of Trim Area to provide free space around the graphicon.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It is recommended not to place graphicon elements inside the crop zone unless absolutely necessary`}</p>
      </li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Place the icons in the Trim Area so that they look symmetrical." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/grid-n-keyline-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Placing the icons outside the safety zone on the grid is not recommended." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/grid-n-keyline-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Sizing`}</h2>
    <p>{`Determining the size or resolution of a graphic is an essential step in the design process. The size of a graphicon depends on its usage, platform, or medium.`}</p>
    <p>{`Here are the guides for determining the suitable graphicon size:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
          <th parentName="tr" {...{
            "align": "right"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Platform`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`1x`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2x`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mobile`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`60px x 60px`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`120px x 120px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tablet`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`120px x 120px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`180px x 180px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Desktop`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`120px x 120px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`180px x 180px`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/sizing.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <p>{`If the icon has no 1:1 ratio (square), the larger side should follow the guide above to make the shape look proportional.`}</p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="The graphicon shape must be proportional." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/sizing-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Adjust the height and width to make it look proportional." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/sizing-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Shape`}</h2>
    <p>{`Using basic shapes such as squares, circles and rectangles to form objects on graphicons is recommended. You can produce softer corners if necessary to provide a more aesthetic appearance.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make the corners more obtuse by 2px or more to create a clear corner effect.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can give volume to a graphicon by adding another shape on the left or right side.`}</p>
      </li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Make the corners more obtuse by 2px or more." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape-do-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Don't use sharp corners." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape-dont-1.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Shape elements must remain in their geometric shape." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape-do-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Shape lements must not be tilted, rotated, or bent." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape-dont-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Create a volume effect on the left or right side of the object." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape-do-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="You cannot place a volume effect above or below an object." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/shape-dont-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Object`}</h2>
    <p>{`Make an icon that is easy to understand, simple, and straightforward because icons that use too much detail make the interface more confusing and difficult to understand. In general, icons that use universal metaphors and can be directly associated with directions for the users have been proven to work better.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/object.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use shadow or outline as a separator between two objects." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/object-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Don't create space between objects." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/object-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Use a maximum of two objects for each icon.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Don’t add more objects, as it will reduce clarity and add unnecessary visual noise`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create space between objects so they are easier to see and understand`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can use shadow or outline`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Alignment`}</h2>
    <p>{`Visually align the elements to ensure that each graphicon looks balanced. Don’t just rely on the numbers. Use your eye and sensitivity to recheck.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/alignment.svg",
      "alt": "grid and keyline illustration"
    }}></img>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Adjust the icon elements and make them look balanced." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/alignment-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Sometimes, you need to recheck and revise the placement from alignment tools." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/graphicon/alignment-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      